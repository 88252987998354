import { Outlet } from 'react-router-dom';
// material

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
